import { applyMiddleware, createStore } from "redux";
import { History } from "history";
import { routerMiddleware } from "connected-react-router";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import { createEpicMiddleware } from "redux-observable";
import { rootReducer } from "store/reducers";
import { rootEpic } from "store/epics";
import { createBrowserHistory } from "history";
import { loggerMiddleware } from "middlewares/loggerMiddleware";
import { apiMiddleware } from "middlewares/api.middleware";
import { environment } from "common/environment";

export const initialize = () => {
  const history = createBrowserHistory();
  const store = configureStore(history);

  return { store, history };
};
export const configureStore = (history: History) => {
  const epicMiddleware = createEpicMiddleware();
  const historyMiddleWare = routerMiddleware(history);
  const store = createStore(
    rootReducer(history),
    configureMiddleWare([historyMiddleWare, apiMiddleware, epicMiddleware, loggerMiddleware])
  );
  epicMiddleware.run(rootEpic);
  return store;
};

export const configureMiddleWare = middleWares => {
  return environment !== "production" ? configureDevMiddleWare(middleWares) : applyMiddleware(...middleWares);
};

const configureDevMiddleWare = middleware => {
  const logger = createLogger({ collapsed: true });
  return composeWithDevTools(applyMiddleware(...middleware, logger));
};
