import { AddressDTO, ContactDTO, ExtendedContactDTO, ImpersonateDTO } from "api/contacts/contact.typings";
import { UserProfile } from "containers/userProfile/userProfile.typings";
import { get, isEmpty, replace, size, startsWith, isNull, first, map } from "lodash";
import { Address, Contact } from "containers/contact/contact.typings";
import { UserDTO } from "api/user/user.typing";
import { mapToApplication } from "containers/userProfile/userProfile.map";

export const mapToUserProfile = (user: ContactDTO, impersonateUsers?: ImpersonateDTO[]): UserProfile => {
  return {
    id: user.id,
    organizationTeamId: user.organizationTeam ? user.organizationTeam.id : "",
    userEmail: user.mainEmail ? user.mainEmail.email : "",
    canImpersonate: !isEmpty(impersonateUsers),
    fullName: formatContactFullName(user.firstname, user.lastname),
    countryIso3: get(first(get(user, "addresses")), "country.iso3"),
    userRight: {
      canValidateCprofitRequest: false,
      canCreateRequest: false,
      canAccessCoverage: false,
    },
  };
};

export const mapToContact = ({
  id,
  givenName,
  name,
  fullName,
  emails,
  civility,
  jobTitle,
  addresses,
  internalHrTeam,
  organizationTeam,
}: ContactDTO): Contact => {
  const teamPath = formatTeamPath(get(organizationTeam, "path", ""));
  const businessLine = formatBusinessLine(teamPath);

  return {
    id,
    firstname: givenName,
    lastname: name,
    department: internalHrTeam,
    fullName,
    email: get(first(emails), "value", ""),
    civility,
    addresses: map(addresses, adress => mapToAddress(adress)),
    teamPath,
    businessLine,
    jobTitle,
  };
};

export const mapToExtendedContact = ({ id, fullName, internalHrTeam, country }: ExtendedContactDTO): Contact => {
  return {
    id,
    fullName,
    internalHrTeam,
    country: get(country, "name"),
  };
};

export const mapToAddress = (address: AddressDTO): Address => ({
  street1: get(address, "street1", ""),
  street2: get(address, "street2", ""),
  street3: get(address, "street3", ""),
  city: get(address, "city", ""),
  country: get(address, "country", ""),
  zipCode: get(address, "zipCode", ""),
});

export const formatTeamPath = (teamPath: string): string =>
  startsWith(teamPath.toUpperCase(), "SGCIB/") ? replace(teamPath, "SGCIB/", "") : teamPath;

export const formatBusinessLine = (teamPath: string): string => {
  const paths = teamPath.split("/");
  return size(paths) > 1 ? paths[0] : teamPath;
};

export const formatContactFullName = (firstname: string, lastname: string) =>
  `${firstname || ""} ${lastname || ""}`.trim();

export const formatUserEmail = (userEmail: string): string =>
  startsWith(userEmail, "uat_") ? replace(userEmail, "uat_", "") : userEmail;

export const mapUserToContact = (contact: Contact, user?: UserDTO): Contact => ({
  ...contact,
  actualRole: user ? (!user.isValidatorCprofit ? "user" : "validator") : "",
  applications: user ? mapToApplication(user) : null,
  isCprofitNewUser: !!user && !isNull(user.validatedRequestCProfit) ? false : true,
  cerberusUserEmail: user ? user.email : "",
});
