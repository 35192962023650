import React = require("react");
import { Contact } from "containers/contact/contact.typings";
import { Loading } from "components/common/Loading/Loading";
import { first, get } from "lodash";
import { ReadOnlyField } from "components/common/ReadOnlyField/ReadOnlyField";
import { isStringEqual, isStringEmpty } from "utils/strings/stringUtils";

export interface StateProps {
  contact: Contact;
  contactId: string;
  isFetching: boolean;
}

export interface DispatchProps {
  onFetchContactById: (contactId?: string) => void;
}

export type Props = StateProps & DispatchProps;

export class ContactViewer extends React.Component<Props> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.handleGetContact = this.handleGetContact.bind(this);
  }

  public componentDidMount() {
    this.handleGetContact();
  }
  public componentDidUpdate(previousProps: Props) {
    if (!isStringEqual(previousProps.contactId, this.props.contactId)) {
      this.handleGetContact();
    }
  }

  public render() {
    const { contact, isFetching } = this.props;
    const address = first(get(contact, "addresses"));
    return (
      <>
        <Loading isVisible={isFetching} />
        {contact && (
          <div className="row my-4">
            <div className="col">
              <div className="row mb-md-2">
                <ReadOnlyField
                  id={"firstname"}
                  label={"First name"}
                  value={contact.firstname}
                  className="col-12 col-sm-6"
                />
                <ReadOnlyField
                  id={"lastname"}
                  label={"Last name"}
                  value={contact.lastname}
                  className="col-12 col-sm-6"
                />
              </div>

              <div className="row mb-md-2">
                <ReadOnlyField id={"email"} label={"Email"} value={contact.email} className="col-12 col-sm-6" />
                <ReadOnlyField
                  id={"department"}
                  label={"Department"}
                  value={contact.department}
                  className="col-12 col-sm-6"
                />
              </div>

              <div className="row mb-md-2">
                <ReadOnlyField id={"city"} label={"City"} value={get(address, "city")} className="col-12 col-sm-6" />
                <ReadOnlyField
                  id={"country"}
                  label={"Country"}
                  value={get(get(address, "country"), "name")}
                  className="col-12 col-sm-6"
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  private handleGetContact() {
    if (!isStringEmpty(this.props.contactId)) {
      this.props.onFetchContactById(this.props.contactId);
    }
  }
}
